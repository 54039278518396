@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  font-size: 16px; /* Set your desired root font size here */
}

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.password-input .ant-input-password-input {
  color: transparent;
  text-shadow: 0 0 0 white;
}

