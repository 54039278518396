.upload-btn{
    background-color: white ;
    color: #5B0F8D;
    border: 1px solid #5B0F8D;
    font-size: 14px !important;
    padding-bottom: 1.8rem;
    padding-top: 0.4rem;
}

.upload-btn:hover{
    background-color: #5B0F8D !important;
    color: white;
    border: 1px solid #5B0F8D !important; 
}