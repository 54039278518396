.sider-wrapper > div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  @apply bg-siderBg !important

}

.tcp-logo {
  width: 124px;
  height: 124px;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-selected {
  @apply text-textColor;
 
}

.ant-menu-item.ant-menu-item-selected {
  @apply text-textColor;
  @apply bg-menuItemSelected;
}

.ant-menu-item:hover {
  @apply text-textColor !important;
  @apply bg-menuItemHighlight !important;
}


.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.bg-transparent.pl-0.text-textSiderDull.text-sm.font-poppins.ant-menu-inline-collapsed.css-dev-only-do-not-override-gzal6t, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical{
  border-inline-end: none !important;
}


:where(.css-dev-only-do-not-override-gzal6t).ant-menu-inline-collapsed >.ant-menu-item, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-inline-collapsed >.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-gzal6t).ant-menu-inline-collapsed >.ant-menu-submenu>.ant-menu-submenu-title{
  padding-inline-start: 1.6rem;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-layout .ant-layout-sider-has-trigger{
  padding-bottom: 0;
}

:where(.css-gzal6t).ant-layout .ant-layout-sider{
  @apply bg-siderBg !important;
}



.ant-dropdown.ant-dropdown-hidden.css-dev-only-do-not-override-gzal6t.ant-dropdown-show-arrow.ant-dropdown-placement-top{
  display: none;
}

.ant-dropdown.ant-dropdown-hidden.css-gzal6t.ant-dropdown-show-arrow.ant-dropdown-placement-top{
  display: none;
}

.ant-dropdown.css-dev-only-do-not-override-gzal6t.ant-dropdown-show-arrow.ant-dropdown-placement-top{
  display: flex;
  justify-content: center;
  z-index: 1001; 
}

.ant-dropdown.css-gzal6t.ant-dropdown-show-arrow.ant-dropdown-placement-top{
  display: flex;
  justify-content: center;
  z-index: 1001; 
}


.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.css-dev-only-do-not-override-gzal6t{
  width: 90% !important;
  @apply bg-white !important;
  border: none !important;
}



:where(.css-gzal6t).ant-dropdown .ant-dropdown-menu, :where(.css-gzal6t).ant-dropdown-menu-submenu .ant-dropdown-menu{
  width: 90%;
}

.menu-item-email{
  color: gray;
  font-size: 0.8rem;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.flex.flex-col.gap-2.css-dev-only-do-not-override-gzal6t li:hover{
  @apply bg-menuItemHighlight !important;
}


@media (max-width: 767px) { 
  .ant-layout-sider.ant-layout-sider-dark.sider-wrapper {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
  }
}
