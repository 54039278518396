.settings-sider-wrapper > div {
    display: flex !important;
    flex-direction: column !important;
    gap: 3rem !important;
    @apply bg-mainBg !important
  }

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
  font-weight: 500;
  background: transparent;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.bg-transparent.pl-0.text-textSiderDull.text-sm.font-poppins.css-dev-only-do-not-override-gzal6t li:hover{
  background: transparent !important;
}

.ant-menu-item.ant-menu-item-active.ant-menu-item-disabled.ant-menu-item-only-child:hover{
  color: rgba(0, 0, 0, 0.25) !important;
}