

.input-field {
  border: 1px solid;
  @apply border-menuItemHighlight;
}

.input-field:focus {
  outline: none;
}

.custom-textarea::placeholder {
  padding-top: 10px;
}

.custom-textarea:focus::placeholder {
  color: transparent;
}

/*  */
.autosize-textarea {
  width: 100%;
  max-height: 45px;
  min-height: 25px;
  height: 25px;
  transition: width 0.25s;
  resize: none;
  margin: 1rem 0.2rem 0.7rem 1.3rem;
  padding: 0 0.5rem 0 0;
  overflow: hidden;
}
/*  */

.upload-btn{
  border: 1px dotted white;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  padding-bottom: 2.2rem;
  padding-top: 0.5rem;
}

.upload-btn:hover{
  border: 1px solid #3F96FE;
}