.ant-row-no-x-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-skeleton-header span {
  @apply bg-loader !important;
}

.ant-modal-footer button {
  @apply text-textColor !important;
}

.ant-upload-icon > span {
  color: white !important;
}

/*  */


.ant-layout .ant-layout-sider-trigger {
  /* top: 2.5rem !important; */
  height: 35px !important;
  line-height: 35px !important;
  background: transparent !important;
}
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left{
  color: black !important;
  background-color: transparent !important;
  top: 50vh;
  transform: translateY(-50%);
}


/* User Management Table Actions */

.action-remove{
  color: black;
}
.action-remove:hover{
  @apply text-actionRemove
}


/* Analytics Ant Grid Column Width Set @ xs */
:where(.css-dev-only-do-not-override-gzal6t).ant-col-xs-24{
  width: 100%;
}


.input-otp{
  width: 100%;
}

/* Hide on screens smaller than 600px */
.desktop-only {
  display: none;
}

/* Show on screens larger than 600px */
@media (min-width: 600px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}

.ant-pagination-item-link { 
  display: none;
}